import React, { Component } from "react";
import validator from "validator";
import FormValidator from "../validations";

import axios from "axios";
const apiUrl = process.env.REACT_APP_API;
const localUrl = process.env.REACT_APP_APIS;
var qs = require("query-string");

class Supportpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mail: {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                requestId: "",
                email: "",
                message: ""
            },
            requestByOrederId: false,
            shipperTab: true,
            carrierTab: false,
            messageFromServer: "",
            messageColor: "success",
            errors: [],
            errorClasses: [],
            shipperFaqList: [],
            carrierFaqList: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.sendMail = this.sendMail.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    _isMounted = false;

    componentDidMount() {
        window.scroll(0, 0);
        document.body.classList.add("support");
        if (document.readyState === "complete") {
        }
    }

    handleOrderId() {
        const query = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true
        });
        if (query.id !== undefined && query.id !== "") {
            const name = "requestId";
            const mail = this.state.mail;
            mail[name] = query.id;

            this.setState(
                {
                    mail,
                    requestByOrederId: true
                },
                () => {
                    this.scrollToView();
                }
            );
        }
    }

    componentWillUnmount() {
        var currentComponent = this;
        document.body.classList.remove("support");
        currentComponent._isMounted = false;
    }

    handleValidation() {
        let errors = {};
        let errorClasses = {};
        let formIsValid = true;
        const validationRules = [
            {
                field: "firstName",
                method: validator.isEmpty,
                validWhen: false,
                message: "Name field is required."
            },
            {
                field: "email",
                method: validator.isEmpty,
                validWhen: false,
                message: "Email field is required."
            },
            {
                field: "email",
                method: validator.isEmail,
                validWhen: true,
                message: "Please enter a valid email address."
            },
            {
                field: "message",
                method: validator.isEmpty,
                validWhen: false,
                message: "Message field is required."
            }
        ];

        const validatorObj = new FormValidator(validationRules);
        const validation = validatorObj.validate(this.state.mail);
        if (!validation.isValid) {
            formIsValid = false;
            for (var variable in validation) {
                if (validation.hasOwnProperty(variable) && variable !== "isValid") {
                    if (validation[variable]["isInvalid"]) {
                        errors[variable] = validation[variable]["message"];
                        errorClasses[variable] = "is-invalid";
                    }
                }
            }
        }
        this.setState({ errors: errors, errorClasses: errorClasses });
        return formIsValid;
    }

    handleChange(e) {
        const name = e.target.name;
        const mail = this.state.mail;
        mail[name] = e.target.value;
        this.setState({
            mail
        });
    }

    handleKeyPress = event => {
        if (event.key === "Enter") {
            let currentComponent = this;
            currentComponent.sendMail(event);
        }
    };

    sendMail(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            console.log('send')
            var currentComponent = this;
            let postdata = {
                name: this.state.mail.firstName,
                email: this.state.mail.email,
                phone: this.state.mail.phoneNumber,
                message: this.state.mail.message
            };

            axios
                .post('https://api.twinly.app/api/v1/contact/send', postdata)
                .then(function(response) {
                    // console.log(response)
                    if (response.data.error === null) {
                        currentComponent.setState({
                            messageFromServer: response.data.payload,
                            mail: {
                                firstName: "",
                                phoneNumber: "",
                                email: "",
                                message: ""
                            },
                            requestByOrederId: false
                        });
                        currentComponent.firstName.value = "";
                        currentComponent.email.value = "";
                        currentComponent.phoneNumber.value = "";
                        currentComponent.message.value = "";

                        setTimeout(function() {
                            currentComponent.setState({
                                messageFromServer: ""
                            });
                        }, 5000);
                    }
                });
        }
    }

    handleTabChange(e) {
        var tabIndex = e.target.id;

        if (tabIndex === "shipper-tab") {
            this.setState({
                shipperTab: true,
                carrierTab: false
            });
        } else {
            this.setState({
                shipperTab: false,
                carrierTab: true
            });
        }
    }

    scrollToView() {
        if (this._isMounted === true) {
            setTimeout(function() {
                let el = document.getElementById("contact-form");
                let elementPosition = el.offsetTop - 0;
                window.scroll({
                    top: elementPosition,
                    left: 0,
                    behavior: "smooth"
                });
            }, 100);
        }
    }

    render() {

        return (
            <div className="support-container">
                <section id="contact-form" className="contact-form">
                    <div className="wrapper">
                        <div className="section-title">Contact Us</div>
                        <div className="text">
                            Faced some issue, want to suggest something or just want to chat?
                            Our team is ready to discuss anything just drop us a message below.
                        </div>
                        <div className="form-wrap">
                            <div className="row">
                                    <div className="label">Name</div>
                                    <input
                                        onKeyPress={this.handleKeyPress}
                                        type="text"
                                        tabIndex="1"
                                        ref={el => (this.firstName = el)}
                                        name="firstName"
                                        className={this.state.errorClasses.firstName}
                                        onChange={this.handleChange}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.errors.firstName}
                                    </div>
                            </div>
                            <div className="row">
                                    <div className="label">Phone number</div>
                                    <input
                                        onKeyPress={this.handleKeyPress}
                                        type="text"
                                        tabIndex="3"
                                        ref={el => (this.phoneNumber = el)}
                                        name="phoneNumber"
                                        onChange={this.handleChange}
                                    />
                            </div>
                            <div className="row">
                                <div className="label">Email</div>
                                <input
                                    onKeyPress={this.handleKeyPress}
                                    type="email"
                                    tabIndex="5"
                                    ref={el => (this.email = el)}
                                    name="email"
                                    className={this.state.errorClasses.email}
                                    onChange={this.handleChange}
                                />
                                <div className="invalid-feedback">
                                    {this.state.errors.email}
                                </div>
                            </div>
                            <div className="row">
                                <div className="label">Message</div>
                                <textarea
                                    onKeyPress={this.handleKeyPress}
                                    tabIndex="6"
                                    name="message"
                                    ref={el => (this.message = el)}
                                    className={this.state.errorClasses.message}
                                    onChange={this.handleChange}
                                ></textarea>
                                <div className="invalid-feedback">
                                    {this.state.errors.message}
                                </div>
                            </div>
                            <div className="bottom row ">
                                <button className="noSelect" onClick={this.sendMail}>
                                    <span>Submit</span>
                                </button>
                            </div>
                            {this.state.messageFromServer ? (
                                <div className="message-box">
                                    <div className="message">{this.state.messageFromServer}</div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Supportpage;
