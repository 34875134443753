import Homepage from './components/pages/home'
import LegalPage from './components/pages/legal'
import Aboutpage from './components/pages/about'
import ContactPage from './components/pages/contact'
// import NotFound from './components/pages/notFound'

const routes = [
    { path: '/', exact: true, name: 'Home page', component: Homepage },
    { path: '/about', exact: true, name: 'About page', component: Aboutpage },
    { path: '/contact', exact: true, name: 'Contact page', component: ContactPage },
    // { path: '/not-found', exact: true, name: 'Not Found', component: NotFound },
    { path: '/legal', exact: true, name: 'Legal page', component: LegalPage },
];


export default routes
