import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Helmet } from 'react-helmet';

import './Assets/css/style.css';
import {
    Router,
    Route,
    Switch,Redirect
} from 'react-router-dom';
import routes from './routes';
import Header from './components/layout/header'
import Footer from './components/layout/footer'

const history = createBrowserHistory();

class BaseApp extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        function hasTouch() {
            return 'ontouchstart' in document.documentElement
                || navigator.maxTouchPoints > 0
                || navigator.msMaxTouchPoints > 0;
        }

        if (hasTouch()) { // remove all :hover stylesheets
            try { // prevent exception on browsers not supporting DOM styleSheets properly
                for (var si in document.styleSheets) {
                    var styleSheet = document.styleSheets[si];
                    if (!styleSheet.rules) continue;

                    for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                        if (!styleSheet.rules[ri].selectorText) continue;

                        if (styleSheet.rules[ri].selectorText.match(':hover')) {
                            styleSheet.deleteRule(ri);
                        }
                    }
                }
            } catch (ex) {}
        }
        return (
                <Router history={history} basename="/">
                    <Helmet>
                        <title>Twinly Application</title>
                        <meta name="description" content="Twinly is an anonimous communication service" />
                        <meta name="theme-color" content="#000" />
                    </Helmet>
                        <Header />
                        <Switch onChange={this.hendelClick}>
                            {routes.map((route, idx) => {
                                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (

                                            <route.component {...props} content={route.content}  key={new Date().getTime()} />

                                        )} /> )
                                        : (null);
                                },
                            )}
                            <Redirect from="/" to="/not-found" />
                        </Switch>
                        <Footer/>
                </Router>
        );
    }

}


export default BaseApp;
