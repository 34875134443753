import React, { Component } from 'react';
import AboutImage from "../../Assets/images/about-image.jpg";

class Aboutpage extends Component {

    render(){
        window.scroll(0, 0);

        return(
            <div className="about-twin-container">
                <section className="about">
                    <div className="wrapper">
                        <div className="text-box">
                            <div className="table">
                                <div className="cell">
                                        <div className="title">About</div>
                                        <div className="text">Everyone owns a unique world with known and unknown sides, with overt or covert emotions, expectations, perceptions, desires, and fears, with a sequence of short or long stories. Here in Twinly, we provide you with a possibility to open, discuss, and discover your world in absolute anonymity. We provide you the opportunity to become a part of an exited journey feeling someone’s world. Twinly is a bidirectional communication channel between you and some stranger, some absolute anonyme stranger, someone, who you can call as a soulmate.</div>
                                        <div className="text-element-subtitle">Keep your anonymity using Twinly! Absolute anonymity is the possible key to absolute sincerity and complete self-recognition.</div>
                                </div>
                            </div>
                        </div>
                        <div className="image-box">
                            <img alt="" src={AboutImage} />
                        </div>
                    </div>
                </section>
            </div>
        )

    }


}


export default Aboutpage;


// .about-twin-container section.about .text-box .title
// .about-twin-container section.about .text-box .text
