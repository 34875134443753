import React from "react";
import { ReactComponent as ReactLogo } from "../Assets/images/anim1.svg";
import styled, { keyframes } from "styled-components";

const fade = (primaryColor, secondaryColor) => keyframes`
0% {
fill:${primaryColor};
}
50% {
 fill:${secondaryColor};
}
100%{
  fill:${primaryColor};
}
`;

const pulse = keyframes`
0% {
  transform: scale(0);
  opacity: 0.5;
  transform-origin: center;
}
100% {
  transform: scale(1);
  opacity: 0;
  transform-origin: center;
}
`;
const pulse1 = keyframes`
0% {
  transform: scale(0);
  opacity: 0.5;
  transform-origin: center;
}
100% {
  transform: scale(1);
  opacity: 0;
  transform-origin: center;
}
`;
const pulse2 = keyframes`
0% {
  transform: scale(0);
  opacity: 0.5;
  transform-origin: center;
}
100% {
  transform: scale(1);
  opacity: 0;
  transform-origin: center;
}
`;

const StyledLogo = styled(ReactLogo)`
  .lines {
    animation: ${props => fade(props.primaryColor, props.secondaryColor)}
      infinite 8s linear;
  }
  .circle {
    animation: ${pulse} infinite 4s linear;
  }
  .circle1 {
    animation: ${pulse1} infinite 3s linear;
  }
  .circle2 {
    animation: ${pulse2} infinite 2s linear;
  }
`;

function Logo() {
    return (
        <div className="svgContainer">
            <StyledLogo primaryColor="#61DAFB" secondaryColor="violet" />
        </div>

    );
}

export default Logo;
