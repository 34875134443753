import React, { Component } from "react";
import ModalVideo from 'react-modal-video'
import ProgressiveImage from 'react-progressive-image';

// import AboutBaner from "../../Assets/images/anima.gif";
import AboutBaner from "../logo";
import CarrierBanner from "../../Assets/images/top-banner.jpg";
import CarrierBannerPlace from "../../Assets/images/top-banner_place.jpg";

import AppleStoreImage from "../../Assets/images/appStore.png";
import PlayStoreImageSoon from "../../Assets/images/playmsoon.jpg";

import 'react-modal-video/scss/modal-video.scss';
import '../../Assets/css/style.css';
import {isMobile} from "react-device-detect";
import {NavLink} from "react-router-dom";

class Homepage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal () {
        this.setState({isOpen: true})
    }

    render() {
        window.scroll(0, 0);
        let options = {controls:0, showinfo:0, loop:1, autoplay:1}
        return (
            <div>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} youtube={options} videoId='Sd2fY442P-g' onClose={() => this.setState({isOpen: false})} />
                <div className="home-container">
                    {isMobile ?
                        <section className="about" data-type="left">
                            <div className="wrapper">
                                <div className="image-box">
                                    <ProgressiveImage src={CarrierBanner} placeholder={CarrierBannerPlace}>
                                        {src => <img src={src} alt="an image" />}
                                    </ProgressiveImage>
                                </div>
                                <div className="text-box">
                                    <div className="table">
                                        <div className="cell">
                                            <div className="title">Find your soulmate</div>
                                            <div className="text">
                                                Open the closed sides of your life. Share and discuss your secret stories, feelings, desires, emotions. Everything discussed in Twinly remains in your minds only. No one will ever know who you are and you will never know who you talking to.
                                            </div>
                                            <div className="icon-box">
                                                <div className="item" onClick={this.openModal}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path id="Subtraction_1" data-name="Subtraction 1" d="M8,20a12.089,12.089,0,0,1-2.418-.244,11.934,11.934,0,0,1-4.291-1.806,12.035,12.035,0,0,1-4.348-5.28,11.94,11.94,0,0,1-.7-2.253,12.117,12.117,0,0,1,0-4.837A11.934,11.934,0,0,1-1.95,1.291a12.035,12.035,0,0,1,5.28-4.348,11.94,11.94,0,0,1,2.253-.7,12.117,12.117,0,0,1,4.837,0A11.934,11.934,0,0,1,14.71-1.95a12.035,12.035,0,0,1,4.348,5.28,11.94,11.94,0,0,1,.7,2.253,12.117,12.117,0,0,1,0,4.837,11.934,11.934,0,0,1-1.806,4.291,12.035,12.035,0,0,1-5.28,4.348,11.94,11.94,0,0,1-2.253.7A12.088,12.088,0,0,1,8,20ZM4.709,1.777A.792.792,0,0,0,4.164,2a.777.777,0,0,0-.238.565v10.93a.777.777,0,0,0,.238.565.792.792,0,0,0,.544.221.77.77,0,0,0,.384-.1l9.6-5.465a.787.787,0,0,0,0-1.366L5.093,1.88A.768.768,0,0,0,4.709,1.777Z" transform="translate(4 4)"/>
                                                    </svg>
                                                    <div className="title">Watch Video</div>
                                                </div>
                                            </div>
                                            <div className="store-icons">
                                                <a className='store-icon-image' href="https://apps.apple.com/us/app/id1474143435" target="_blank"><img src={AppleStoreImage} alt='appStore image'/></a>
                                                <img alt="" src={PlayStoreImageSoon} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        <section className="about" data-type="left">
                            <div className="wrapper">
                                <div className="text-box">
                                    <div className="table">
                                        <div className="cell">
                                            <div className="title">Find your soulmate</div>
                                            <div className="text">
                                                Open the closed sides of your life. Share and discuss your secret stories, feelings, desires, emotions. Everything discussed in Twinly remains in your minds only. No one will ever know who you are and you will never know who you talking to.
                                            </div>
                                            <div className="icon-box">
                                                <div className="item" onClick={this.openModal}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path id="Subtraction_1" data-name="Subtraction 1" d="M8,20a12.089,12.089,0,0,1-2.418-.244,11.934,11.934,0,0,1-4.291-1.806,12.035,12.035,0,0,1-4.348-5.28,11.94,11.94,0,0,1-.7-2.253,12.117,12.117,0,0,1,0-4.837A11.934,11.934,0,0,1-1.95,1.291a12.035,12.035,0,0,1,5.28-4.348,11.94,11.94,0,0,1,2.253-.7,12.117,12.117,0,0,1,4.837,0A11.934,11.934,0,0,1,14.71-1.95a12.035,12.035,0,0,1,4.348,5.28,11.94,11.94,0,0,1,.7,2.253,12.117,12.117,0,0,1,0,4.837,11.934,11.934,0,0,1-1.806,4.291,12.035,12.035,0,0,1-5.28,4.348,11.94,11.94,0,0,1-2.253.7A12.088,12.088,0,0,1,8,20ZM4.709,1.777A.792.792,0,0,0,4.164,2a.777.777,0,0,0-.238.565v10.93a.777.777,0,0,0,.238.565.792.792,0,0,0,.544.221.77.77,0,0,0,.384-.1l9.6-5.465a.787.787,0,0,0,0-1.366L5.093,1.88A.768.768,0,0,0,4.709,1.777Z" transform="translate(4 4)"/>
                                                    </svg>
                                                    <div className="title">Watch Video</div>
                                                </div>
                                            </div>
                                            <div className="store-icons">
                                                <a className='store-icon-image' href="https://apps.apple.com/us/app/id1474143435" target="_blank"><img src={AppleStoreImage} alt='appStore image'/></a>
                                                <img alt="" src={PlayStoreImageSoon} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="image-box">
                                    <img alt="" src={CarrierBanner} />
                                </div>
                            </div>
                        </section>
                    }
                    <section className="gifSection" id="about" data-type="right">
                        <div className="wrapper">
                            <div className="image-box">
                                <AboutBaner />
                            </div>
                            <div className="text-box">
                                <div className="table">
                                    <div className="cell">
                                            <div className="title">Twinly is not a dating app</div>
                                            <div className="text-element-subtitle">AI based algorithm finds a person for you to communicate with.</div>
                                            <div className="text">Twinly creates a bidirectional communication between you and someone, someone absolutely anonyme , but at the same time someone very close and soulmate.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="benefitsSection" id="details" data-type="right">
                        <div className="wrapper">
                                <div className="column-element">
                                    <div className="icon-box">
                                        <div className="item">
                                            <div className="benefit-gif">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                                    <g id="Group_536" data-name="Group 536" transform="translate(-391 -2090)">
                                                        <g id="Group_342" data-name="Group 342" transform="translate(90.001 2286.36)">
                                                            <path id="Path_1225" data-name="Path 1225" d="M421.22-166.17c-.338-1.582-.832-3.614-1.491-5.663a25.781,25.781,0,0,0-2.759-6.177c-1.465-2.223-3.156-3.35-5.025-3.35a4.148,4.148,0,0,0-3.861,1.916,5.5,5.5,0,0,1-.341.439l-.015.016a1.124,1.124,0,0,1-.834.341,1.124,1.124,0,0,1-.835-.342l-.012-.014a5.48,5.48,0,0,1-.342-.44,4.149,4.149,0,0,0-3.861-1.916c-1.87,0-3.561,1.127-5.025,3.35a25.778,25.778,0,0,0-2.759,6.177c-.656,2.038-1.148,4.059-1.486,5.637Z" transform="translate(-78.403 0)"/>
                                                            <path id="Path_1226" data-name="Path 1226" d="M345.178,42.087l-33.465-.029a4.892,4.892,0,0,1-4.659-3.366,1.13,1.13,0,0,1,1.083-1.477l40.624.035a1.13,1.13,0,0,1,1.08,1.478A4.889,4.889,0,0,1,345.178,42.087Z" transform="translate(0 -200.261)"/>
                                                        </g>
                                                        <rect id="Rectangle_705" data-name="Rectangle 705" width="54" height="54" transform="translate(391 2090)" fill="none"/>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="column-title">Anonymity</div>
                                            <div className="column-text-element">Your anonymity is our first priority. No sign-up/sign-in needed, No personal information collected.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column-element">
                                    <div className="icon-box">
                                        <div className="item">
                                            <div className="benefit-gif">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                                    <g id="Group_537" data-name="Group 537" transform="translate(-790 -2090)">
                                                        <g id="Group_343" data-name="Group 343" transform="translate(741 2101)">
                                                            <g id="Group_338" data-name="Group 338" transform="translate(64)">
                                                                <path id="Path_1227" data-name="Path 1227" d="M87.085,11.872H85.107V9.234a9.234,9.234,0,0,0-18.468,0v2.638H64.66a.659.659,0,0,0-.66.66v16.49a2.641,2.641,0,0,0,2.638,2.638H85.107a2.641,2.641,0,0,0,2.638-2.638V12.532A.659.659,0,0,0,87.085,11.872ZM77.847,25.651a.66.66,0,0,1-.656.732H74.553a.66.66,0,0,1-.656-.732l.416-3.742a2.609,2.609,0,0,1-1.08-2.122,2.638,2.638,0,1,1,5.277,0,2.61,2.61,0,0,1-1.08,2.122Zm3.3-13.778H70.6V9.234a5.277,5.277,0,1,1,10.553,0Z" transform="translate(-64)"/>
                                                            </g>
                                                        </g>
                                                        <rect id="Rectangle_704" data-name="Rectangle 704" width="54" height="54" transform="translate(790 2090)" fill="none"/>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="column-title">Security</div>
                                            <div className="column-text-element">Twinly messaging is using End-to-End encryption in combination with 256-bit symmetric AES encryption.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column-element">
                                    <div className="icon-box">
                                        <div className="item">
                                            <div className="benefit-gif">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                                    <g id="Group_538" data-name="Group 538" transform="translate(-1230 -2090)">
                                                        <path id="Path_1231" data-name="Path 1231" d="M-602.089-392.707l9.689,14.063a5.455,5.455,0,0,1-3.14.988,5.141,5.141,0,0,1-4.309-2.186l-6.691-9.519a5.353,5.353,0,0,0-4.212-2.044,5.362,5.362,0,0,0-5.362,5.362,5.362,5.362,0,0,0,5.362,5.362,5.34,5.34,0,0,0,3.209-1.066l2.989-2.048,3.215,4.469-3.884,2.712a10.88,10.88,0,0,1-5.53,1.5,10.929,10.929,0,0,1-10.929-10.929,10.929,10.929,0,0,1,10.929-10.929A10.912,10.912,0,0,1-602.089-392.707Zm19.85-4.264a10.878,10.878,0,0,0-5.529,1.5l-3.884,2.712,3.215,4.469,2.989-2.048a5.339,5.339,0,0,1,3.209-1.066,5.362,5.362,0,0,1,5.362,5.362,5.362,5.362,0,0,1-5.362,5.362,5.353,5.353,0,0,1-4.212-2.044l-6.691-9.519a5.142,5.142,0,0,0-4.309-2.186,5.454,5.454,0,0,0-3.14.988l9.689,14.063a10.911,10.911,0,0,0,8.662,4.264,10.929,10.929,0,0,0,10.93-10.929A10.929,10.929,0,0,0-582.239-396.971Z" transform="translate(660.691 1730.888) rotate(-180)"/>
                                                        <rect id="Rectangle_703" data-name="Rectangle 703" width="54" height="54" transform="translate(1230 2090)" fill="none"/>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="column-title">One to One</div>
                                            <div className="column-text-element">One to One connection that is created between you and a person you are connected with is much more than that.</div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default Homepage;
